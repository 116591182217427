export const price = [
  {
    id: 0,
    from: '15',
    fromMonth: 'april',
    to: '10',
    toMonth: 'june',
    price: '350-450 PLN',
  },
  {
    id: 1,
    from: '16',
    fromMonth: 'june',
    to: '30',
    toMonth: 'june',
    price: '450 PLN',
  },
  {
    id: 2,
    from: '1',
    fromMonth: 'july',
    to: '22',
    toMonth: 'august',
    price: '550-600 PLN',
  },
  {
    id: 3,
    from: '23',
    fromMonth: 'august',
    to: '31',
    toMonth: 'august',
    price: '450 PLN',
  },
  {
    id: 4,
    from: '1',
    fromMonth: 'september',
    to: '10',
    toMonth: 'october',
    price: '350-450 PLN',
  },
];
