export const recommendation = [
  {
    name: 'Gosia',
    type: 'typeRecommendation',
    text: 'recommendation1',
  },
  {
    name: 'Kamil',
    type: 'typeRecommendation',
    text: 'recommendation2',
  },
  {
    name: 'Daria',
    type: 'typeRecommendaiton2',
    text: 'recommendaiton3',
  },
];
