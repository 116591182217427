import React from 'react';
import { Link, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { CarouselProvider, Slider, Slide, Dot } from 'pure-react-carousel';
import Layout from '../components/Layout';
import { recommendation } from '../assets/recommendation/index';
import { useTranslation } from 'react-i18next';
import { price } from '../assets/price/price';
import config from '../../config';
import 'pure-react-carousel/dist/react-carousel.es.css';
const Index = ({ data }) => {
  const { t } = useTranslation();
  return (
    <Layout fullMenu={false}>
      <section id="banner">
        <div className="inner">
          <div className="logo">
            <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              className={'icon'}
            />
          </div>
          <h2>{config.heading}</h2>
          <p>{t(config.subHeading)}</p>
        </div>
      </section>

      <section id="wrapper">
        <section id="one" className="wrapper spotlight style1">
          <div className="inner">
            <Link to="/gallery" className="image">
              <GatsbyImage image={data.img1.childImageSharp.gatsbyImageData} />
            </Link>
            <div className="content">
              <h2 className="major">{t('aboutHeader')}</h2>
              <p>{t('aboutHeaderDescription')}</p>
            </div>
          </div>
        </section>

        <section id="two" className="wrapper alt spotlight style2">
          <div className="inner">
            <Link to="/gallery" className="image">
              <GatsbyImage image={data.img3.childImageSharp.gatsbyImageData} />
            </Link>

            <div className="content">
              <h2 className="major">{t('aboutHousesHeader')}</h2>
              <p>{t('aboutHousesDescription')}</p>
              <Link to="/equipment" className="special">
                {t('equipmentHouses')}
              </Link>
            </div>
          </div>
        </section>

        <section id="three" className="wrapper spotlight style3">
          <div className="inner">
            <Link to="/gallery" className="image">
              <GatsbyImage image={data.img2.childImageSharp.gatsbyImageData} />
            </Link>
            <div className="content">
              <h2 className="major">{t('aboutAround')}</h2>
              <p>{t('aboutAroundDescription')}</p>
              <Link to="/attractions" className="special">
                {t('attractionInAround')}
              </Link>
            </div>
          </div>
        </section>

        <section id="four" className="wrapper newAlt style6">
          <div className="inner">
            <div className="content">
              <h2 className="major">
                {t('priceHeader')} {new Date().getFullYear()} /
                {new Date().getFullYear() + 1}
              </h2>
              <div className="table-wrapper">
                <table>
                  <thead>
                    <tr>
                      <th>{t('data')}</th>
                      <th>{t('price')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {price.map((item) => (
                      <tr key={item.id}>
                        <td>
                          {t('from')}{' '}
                          <b>
                            {' '}
                            {item.from} {t(item.fromMonth)}
                          </b>{' '}
                          {t('to')}
                          <b>
                            {' '}
                            {item.to} {t(item.toMonth)}
                          </b>
                        </td>
                        <td>
                          {item.price} {t('hotelNight')}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <p>
                  {t('priceNight')} <b>7 {t('people')}</b>,{' '}
                  {t('accomadationMaximum')} <b>9 {t('people')}</b>{' '}
                  {t('additionalPeople')} <b>50 zł {t('hotelNight')}</b>
                  <p>
                    {t('stayDog')} <b>15 zł {t('hotelNight')}</b>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="five" className="wrapper alt style5">
          <div className="inner">
            <h2 className="major">{t('gallery')}</h2>
            <section className="features">
              {[data.img4, data.img5, data.img6, data.img7].map((pic, key) => {
                return (
                  <React.Fragment key={key}>
                    <article>
                      <GatsbyImage
                        image={pic.childImageSharp.gatsbyImageData}
                      />
                    </article>
                  </React.Fragment>
                );
              })}
            </section>
            <ul className="actions">
              <li>
                <Link to="/gallery" className="button">
                  {t('showAll')}
                </Link>
              </li>
            </ul>
          </div>
        </section>
        <section id="six" className="wrapper newSpotlight style4">
          <div className="inner">
            <div className="content">
              <h2 className="major">{t('ourRecommendationHeader')}</h2>
              <p>{t('ourRecommendationHeader')}</p>
              <CarouselProvider
                className="carousel-root"
                naturalSlideWidth={120}
                naturalSlideHeight={120}
                totalSlides={3}
                interval={6000}
                isPlaying={true}
                infinite={true}
              >
                <Slider className="carousel-slider">
                  {recommendation.map((rec, index) => (
                    <React.Fragment key={index}>
                      <Slide index={index}>
                        <>
                          <h3>
                            <b>{rec.name}</b> {t('trip')}: {t(rec.type)}
                          </h3>
                          {t(rec.text)}
                        </>
                      </Slide>
                    </React.Fragment>
                  ))}
                </Slider>
                <Dot slide={0} className={'btn-ratio'} />
                <Dot slide={1} className={'btn-ratio'} />
                <Dot slide={2} className={'btn-ratio'} />
              </CarouselProvider>
            </div>
          </div>
        </section>
      </section>
    </Layout>
  );
};
export default Index;
export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, height: 125, width: 120)
      }
    }
    img1: file(relativePath: { eq: "houses/img1.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 276, width: 276, quality: 100)
      }
    }
    img2: file(relativePath: { eq: "attractions/IMG_7682.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 276, width: 276, quality: 100)
      }
    }
    img3: file(relativePath: { eq: "houses/img17.webp" }) {
      childImageSharp {
        gatsbyImageData(height: 276, width: 276, quality: 100)
      }
    }
    img4: file(relativePath: { eq: "houses/img1.webp" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 300
          width: 300
          quality: 100
        )
      }
    }
    img5: file(relativePath: { eq: "houses/img8.webp" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 300
          width: 300
          quality: 100
        )
      }
    }
    img6: file(relativePath: { eq: "houses/img3.webp" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 300
          width: 300
          quality: 100
        )
      }
    }
    img7: file(relativePath: { eq: "houses/img4.webp" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          height: 300
          width: 300
          quality: 100
        )
      }
    }
  }
`;
